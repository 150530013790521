.gpt3__header {
  display: flex;
  background-color: #ce050d;
  
  
}

.gpt3__header-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  margin-right: 5rem;
}

.gpt3__header-content h1 {
  font-family: var(--font-family);
  font-weight: 800;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
  margin-bottom: 60px;
}

.gpt3__header-content p {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: var(--color-text);
}

.gpt3__header-content__input {
  width: 100%;
  margin: 2rem 0 1rem;
  display: flex;
  flex-direction: row;
}

.gpt3__header-content__input input {
  flex: 2;
  width: 100%;
  min-height: 50px;
  font-size: 20px;
  line-height: 27px;
  font-family: var(--font-family);
  background: var(--color-footer);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 2px solid var(--color-footer);
  padding: 0 1rem;
  outline: none;
  color: #fff;
}

.gpt3__header-content__input button {
  flex: 0.6;
  width: 100%;
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: bold;
  line-height: 27px;
  border: none;
  color: #fff;
  border: none;
  background-color: #ff4820;
  padding: 0 1rem;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.gpt3__header-content__people {
    width:100%; 
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
    margin-top: 2rem;

}

.gpt3__header-content__people img {
    width: 181.79px;
    height: 38px;

}

.gpt3__header-content__people p {
    margin : 0 0 0 1rem ;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.gpt3__header-image {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.gpt3__header-image img {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  width: 80%;
}

.gpt3__header-image p{ 
  font-size: 0.9rem;
  text-align: center;
  font-family: var(--font-family);
  color: #fff;
  width: 60%;
}

@media screen and (max-width: 1050px) {
  .gpt3__header{
    flex-direction: column;

  }

  .gpt3__header-content {
    margin: 0 0 3rem;  
  }
}


@media screen and (max-width: 650px) {
  .gpt3__header h1{
    font-size: 48px;
    line-height: 60px;
  }

  .gpt3__header p{
    font-size: 16px;
    line-height: 24px;
  }

  .gpt3__header-content__people {
    flex-direction: column;
  }

  .gpt3__header-content__people p {
    margin: 0;
  }

  .gpt3__header-content__input input,
  .gpt3__header-content__input button {
    font-size: 16px;
  }
}

@media screen and (max-width: 490px) {
  .gpt3__header h1{
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 30px;

  }

  .gpt3__header p{
    /* Just checking that rem works everywhere or not */
    /* font-size: 1rem; */
    font-size: 14px;
    line-height: 24px;

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
      font-size: 14px;
    }
  }
  .gpt3__header-content__input input,
  .gpt3__header-content__input button {
    font-size: 12px;
    line-height: 16px;
  }

  .gpt3__header-image p{ 
    font-size: 0.8rem;
    text-align: center;
    margin: 0 5px;
  }
}